import {
  bankAccount,
  company,
  settingOptions,
} from "~/constants/apiRoute/company"
import { useTable } from "~/stores/table"
import type { IResponder } from "~/interfaces/iResponder"
import type { IEntity } from "~/layers/company/interfaces/iEntity"
import { companyUser } from "~/constants/apiRoute/auth"

export const getCompanies = async (options: any = {}): Promise<IResponder> =>
  await useApiFetch(`${company}`, {
    query: options,
  })
export const postCompany = async (
  body: any,
  options: any,
): Promise<IResponder> => await useSave(`${company}`, body, options)

export const showCompany = async (id: string | string[]): Promise<IResponder> =>
  await useApiFetch(`${company}/${id}`)

export const getOrgChart = async (id: string | string[]): Promise<IResponder> =>
  await useApiFetch(`${company}/${id}/organization-chart`)

export const postFiles = async (
  id: string | string[],
  body: any,
): Promise<IResponder> =>
  await useApiFetch(`${company}/${id}/files`, {
    method: "POST",
    body: body,
  })

export const companyProfile = (id: string | string[]) => {
  const store = useTable()
  const dataChanged = computed({
    get: () => store.dataChanged,
    set: (val) => (store.dataChanged = val),
  })
  const { data, status, error } = useLazyAsyncData("item", async () => {
    const response: IResponder = await showCompany(id)
    if (response.success) {
      return response
    }
  })
  const refresh = async () => await refreshNuxtData("item")
  watch(dataChanged, async (val) => {
    if (val) {
      await refresh()
      dataChanged.value = false
    }
  })
  return {
    data,
    status,
    error,
  }
}

export const companyBankAccounts = async (
  companyId: string,
): Promise<IResponder> =>
  await useApiFetch(`${bankAccount}`, {
    query: {
      company: companyId,
    },
  })

export const postCompanyBankAccount = async (
  body: any,
  options: any,
): Promise<IResponder> => await useSave(`${bankAccount}`, body, options)

export const showCompanyBankAccount = async (
  id: string | string[],
): Promise<IResponder> => await useApiFetch(`${bankAccount}/${id}`)

export const getCompanySettings = async (
  companyId: string | null | undefined,
) => {
  return await useApiFetch(`${settingOptions}`, {
    query: {
      company: companyId,
    },
  })
}
export const getUserCompanies = async () => {
  return await useApiFetch(`${companyUser}`)
}
